import React from "react";
import NavigationIcon from '@mui/icons-material/AssistantDirection';
import BigTruckIcon from '@mui/icons-material/LocalShipping';
import BikeIcon from '@mui/icons-material/DirectionsBike';
import CarIcon from '@mui/icons-material/DirectionsCar';
import SmallTruckIcon from '@mui/icons-material/AirportShuttle';

export default function Menu(props) {

	var cssAnchor, cssDirection;
	switch (props.anchor) {
		case "center":
			cssAnchor = "ta-nav-menu-anchor-center-right";
			break;
		case "top":
			cssAnchor = "ta-nav-menu-anchor-top-right";
			break;
		case "bottom":
			cssAnchor = "ta-nav-menu-anchor-bottom-right";
			break;
		default:
			cssAnchor = "ta-nav-menu-anchor-center-right";
			break;
	}

	switch (props.direction) {
		case "up":
			cssDirection = "ta-nav-menu-direction-up";
			break;
		case "down":
			cssDirection = "ta-nav-menu-direction-down";
			break;
		case "left":
			cssDirection = "ta-nav-menu-direction-left";
			break;
		default:
			cssDirection = "ta-nav-menu-direction-left";
			break;
	}

	return (
		<div className={"ta-nav-menu " + cssAnchor + " " + cssDirection}>

			<div className="ta-nav-start">
				<div className="ta-nav-center">
					<div className="ta-nav-content">
						<NavigationIcon className="ta-nav-icon" />
						<span className="ta-nav-description">
							NAVIGATION
						</span>
					</div>
				</div>
			</div>

			<div className="ta-nav-item" onClick={() => props.onClick('bike')}>
				<div className="ta-nav-center">
					<div className="ta-nav-content">
						<BikeIcon className="ta-nav-icon" />
						<span className="ta-nav-description">
							FAHRRAD
						</span>
					</div>
				</div>
			</div>

			<div className="ta-nav-item" onClick={() => props.onClick('car')}>
				<div className="ta-nav-center">
					<div className="ta-nav-content">
						<CarIcon className="ta-nav-icon" />
						<span className="ta-nav-description">
							AUTO
						</span>
					</div>
				</div>
			</div>

			<div className="ta-nav-item" onClick={() => props.onClick('smalltruck')}>
				<div className="ta-nav-center">
					<div className="ta-nav-content">
						<SmallTruckIcon className="ta-nav-icon" />
						<span className="ta-nav-description">
							LKW &lt; 7.5 t
						</span>
					</div>
				</div>
			</div>

			<div className="ta-nav-item" onClick={() => props.onClick('bigtruck')}>
				<div className="ta-nav-center">
					<div className="ta-nav-content">
						<BigTruckIcon className="ta-nav-icon" />
						<span className="ta-nav-description">
							LKW &gt; 7.5 t
						</span>
					</div>
				</div>
			</div>

		</div>
	);
}